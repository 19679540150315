<template>
    <Layout>

        <div class="row">

            <Talks
                @reset-page="resetPage"
            />

            <div class="col-sm-8" v-if="mentee">
                <div class="card" style="height: calc(100vh - 110px)">
                    <div class="card-header">
                        <div class="card-title d-flex">
                            <img height="40" :src="mentee.profile_image_base64" class="rounded-circle"
                                 alt="Perfil Mentorando"/>
                            <span class="my-auto ml-3">{{ mentee.name }}</span>
                        </div>
                    </div>

                    <div class="border-bottom p-3 mb-3 custom-scroll" id="chat" ref="scrollContainer"
                         :style="{ backgroundImage: 'url(' + require('../../assets/images/chat-bg-pattern.png') + ')' }"
                         style="overflow-y: auto; height: calc(100vh - 190px);" @scroll="handleScroll">
                        <div v-if="messages.data.length > 0">
                            <div class="row" v-for="(message, index) in messages.data"
                                 :key="'message' + message.id">
                                <div class="col">
                                    <div class="row" v-if="verifyDates(index)">
                                        <div class="col text-center my-3">
                                            <span class="bg-light p-2">{{ getDate(message.created_at) }}</span>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <span class="p-3 my-1 message"
                                                  :class="message.target === 'mentee' ? 'bg-light' : 'bg-success'">
                                                {{ message.message }}<br>
                                                <span class="hour text-muted"
                                                      :class="message.target === 'mentee' ? 'float-right' : ''">
                                                    {{ formatHour(message.created_at) }}
                                                    <i class="fa fa-check-circle ml-1"
                                                       v-if="message.target === 'mentee'"
                                                       :class="message.read_at  ? 'text-success' : 'text-muted'"></i>
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-else>
                            <div class="row">
                                <div class="col text-center my-3">
                                    <span class="bg-light p-2">Sem mensagens até o momento</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <form id="form" @submit.prevent="newMessage">
                        <div class="row px-3">
                            <div class="col d-flex mb-3">
                                <div class="bv-no-focus-ring my-auto w-100">
                                    <div role="group" class="input-group input-group-merge form-control-prepended">
                                        <input placeholder="Digite a nova mensagem..." v-model="message"
                                               class="form-control form-control-appended">
                                        <div class="input-group-append">
                                            <button type="submit" class="input-group-text bg-success text-white">
                                                <i class="fa fa-paper-plane"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>


    </Layout>
</template>

<script>

import Layout from '@/components/layout/main.vue';
import Talks from "@/views/chat/talks.vue";
import http from '@/http'
import {formatHour, getDate, isSameDay} from "../../components/composables/masks";

export default {
    data() {
        return {
            messages: {
                data: []
            },
            load: true,
            addChat: false,
            message: "",
            username: "Steven Franklin",
            profile: require("@/assets/images/people/110/guy-1.jpg"),
            mentee: null,
            page: 1,
            search: '',
            listFiltered: [],
            listTotalMentees: [],
            searchTotalMentees: [],
        };
    },
    components: {
        Layout,
        Talks
    },

    methods: {
        formatHour,
        getDate,

        newMessage() {
            if (this.message === '') {
                return;
            }

            const formData = {
                mentor_id: this.currentUser.id,
                message: this.message,
                mentee_id: this.mentee.id,
                target: 'mentee'
            }

            http.post('mentor/messages/save', formData)
                .then(() => {
                    this.message = '';
                })
                .catch(() => {
                    this.$notifyError(null, 'Erro ao enviar Mensagem!');
                });
        },

        handleScroll(event) {
            if (event.target.scrollTop === 0 && this.load && this.messages.count > this.page * 20) {
                this.load = false;
                ++this.page;
                this.getMessages(this.mentee.id);
            }
        },

        resetPage(id) {
          this.page = 1;
            this.getMessages(id);
        },

        verifyDates(index) {
            if (index === 0) {
                return true;
            } else {
                const before = this.messages.data[index - 1];
                const data = this.messages.data[index];
                const date1 = new Date(before.created_at);
                const date2 = new Date(data.created_at);
                return !isSameDay(date2, date1);
            }
        },

        scrollToBottom() {
            this.$nextTick(() => {
                const container = this.$refs.scrollContainer;
                container.scrollTop = container.scrollHeight;
            });
        },

        read() {
            // let self = this;
            // let id = self.$route.params.id;
            // let api = self.$store.state.api + "notifications/readAll";
            // const formData = {
            //     reserve_id: id,
            //     target: 0
            // }
            // self.$http
            //     .post(api, formData)
            //     // .then(() => {
            //     //     console.info('Messages read successfully!')
            //     // })
            //     .catch(() => {
            //         console.error('Error reading message!')
            //     });
        },

        getMessages(id) {
            http.get(`mentor/get-messages/${id}?page=${this.page}`)
                .then(response => {
                    if (this.page == 1) {
                        this.mentee = response.data.mentee
                        const newValue = response.data.messages;
                        newValue.data.sort((a, b) => a.id - b.id);
                        this.messages = newValue;
                        this.scrollToBottom();
                    } else {
                        const container = this.$refs.scrollContainer;
                        const scrollHeightBefore = container.scrollHeight;

                        response.data.messages.data.sort((a, b) => a.id - b.id);
                        this.messages.data = [
                            ...response.data.messages.data,
                            ...this.messages.data,
                        ];

                        this.$nextTick(() => {
                            const scrollHeightAfter = container.scrollHeight;
                            container.scrollTop = scrollHeightAfter - scrollHeightBefore;
                        });
                    }

                    this.load = true;
                })
                .catch(e => {
                    console.error(e);
                })
        },
    },
    mounted() {
        document.getElementById('footer').style.display = 'none';
        const id = this.$route.params.id;
        if (id) this.getMessages(id);
        window.Echo.channel('chat')
            .listen('.message.sent', (e) => {
                if (e.message.mentee_id === this.mentee.id && e.message.mentor_id === this.currentUser.id) {
                    this.messages.data.push(e.message);
                    this.scrollToBottom();
                }
            })
    },
    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        }
    },
};
</script>

<style scoped>
.item-chat:hover {
    background: #F5F7FA !important;
}

.page-section .form-control:focus {
    box-shadow: none !important;
    border-bottom: solid 1px #DBDBE0 !important;
    border-radius: 0 !important;
}

.max {
    width: 60%;
}

span.bg-light.message {
    border-radius: 8px;
    max-width: 75%;
    float: right;
}

span.bg-success.message {
    border-radius: 8px;
    max-width: 75%;
    float: left;
//background: #a3ff75!important; background: #c4fca9 !important;
}

.hour {
    font-size: 10px;
    font-style: italic;
    margin: 0 10px;
}

.op-0 {
    opacity: 0;
}

.hidden {
    visibility: hidden;
}

</style>
